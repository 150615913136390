exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-markdown-remark-fields-slug-tsx": () => import("./../../../src/pages/{MarkdownRemark.fields__slug}.tsx" /* webpackChunkName: "component---src-pages-markdown-remark-fields-slug-tsx" */),
  "component---src-pages-over-tsx": () => import("./../../../src/pages/over.tsx" /* webpackChunkName: "component---src-pages-over-tsx" */),
  "component---src-pages-podcast-tsx": () => import("./../../../src/pages/podcast.tsx" /* webpackChunkName: "component---src-pages-podcast-tsx" */),
  "component---src-pages-puzzels-tsx": () => import("./../../../src/pages/puzzels.tsx" /* webpackChunkName: "component---src-pages-puzzels-tsx" */),
  "component---src-pages-social-media-tsx": () => import("./../../../src/pages/social-media.tsx" /* webpackChunkName: "component---src-pages-social-media-tsx" */)
}

